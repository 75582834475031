import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/foster-application/sections"
import banner from "../images/foster-banner-bg.jpg"

const FosterApplicationPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Foster Application" />
        <InnerBanner theme="white" image={banner} title="Foster Application" />
        <Sections.Section1/>
        <Sections.Section2/>
    </Layout>
}

export default FosterApplicationPage